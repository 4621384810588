<template>
  <div>
    <!-- warning modal-->
    <b-modal
      id="modal-center"
      :visible="isModalSActive"
      :title="'Apply discount rules for course ID: ' + courseId"
      size="md"
      hide-footer
      centered
      no-close-on-backdrop
      @change="(val) => $emit('update:isModalSActive', val)"
    >
      <!-- start of form-->
      <b-row class="mb-1">
        <b-col md="4" class="mt-2 pl-1">
          <b-form-checkbox
              v-model="isEarlyBird"
              class="custom-control-primary d-inline-block mr-50 mb-1"
          />
          <small>Early bird ?</small>
        </b-col>
        <b-col md="4">
          <b-form-group
              label="Days before course starts"
              label-for="days"
          >
            <b-form-input
                id="days"
                v-model="earlyBirdDays"
                placeholder="Total days"
                class="input"
                type="number"
            />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group
              label="Discount Percent"
              label-for="discount"
          >
            <b-form-input
                id="discount"
                v-model="earlyBirdDiscountPercent"
                placeholder="Discount amount"
                class="input"
                type="number"
            />
          </b-form-group>
        </b-col>

        <!-- early bird message -->
        <b-col  md="12">
          <b-form-group
              label="Early bird Message"
              label-for="ebmessage"
          >
            <small>Shortcuts: </small>
            <small>
              <span v-for="(symbol, index) in helpers.earlyBird.symbols"
                    v-bind:key="index"
                    class="input-shortcut"
                    @click="insertShortcut(symbol.symbol, helpers.earlyBird.inputRef, 'earlybirdMessage')"
                    v-b-tooltip.hover.top="symbol.description">
                {{ symbol.symbol }}
              </span>,
            </small>
            <b-form-input
                id="ebmessage"
                v-model="earlybirdMessage"
                placeholder="Marketing attractive message"
                class="input"
                type="text"
                ref="earlybirdMessageInput"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <hr class="mb-3 mt-2">
      <!-- discounts -->
      <b-row class="mb-1">
        <b-col md="4" class="mt-2 pl-1">
          <b-form-checkbox
              v-model="isDiscounted"
              class="custom-control-primary d-inline-block mr-50 mb-1"
          />
          <small>Discounted ?</small>
        </b-col>
        <b-col md="4">
          <b-form-group
              label="Discount type"
              label-for="type"
          >
            <v-select
                v-model="selectedType"
                label="Type"
                :options="typesOptions"
                :clearable="false"
            >
                  <span slot="no-options">
                    <div
                        v-if="isTyping"
                        class="text-center d-flex align-items-center justify-content-center"
                    >
                      <b-spinner
                          variant="primary"
                          label="Text Centered"
                      />
                    </div>
                    <div
                        v-if="!isTyping && !typesOptions.length"
                        class="text-center d-flex align-items-center justify-content-center"
                    >
                      No matching records found
                    </div>
                  </span>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group
              :label="'Discount ' + selectedType"
              label-for="discount"
          >
            <b-form-input
                id="discount"
                v-model="discountAmount"
                placeholder="Discount amount"
                class="input"
                type="number"
            />
          </b-form-group>
        </b-col>

      </b-row>
      <!-- discounts date start -->
      <b-row class="mb-1">

        <!-- discounts date start -->
        <b-col md="6">
          <b-form-group
              label="Discount Start date"
              label-for="dstart"
          >
            <flat-pickr
                v-model="discountStart"
                class="form-control"
                placeholder="Select start date"
                :config="{
                  altInput: true,
                  altFormat: 'j-m-Y at H:i',
                  enableTime: true,
                  dateFormat: 'Y-m-d H:i:s',
                  // minDate: Date.now(),
                  // defaultDate: Date.now(),
                  time_24hr: true,
                }"
            />
          </b-form-group>
        </b-col>

        <!-- discounts date end -->
        <b-col md="6">
          <b-form-group
              label="Discount End date"
              label-for="estart"
          >
            <flat-pickr
                v-model="discountEnd"
                class="form-control"
                placeholder="Select end date"
                :config="{
                  altInput: true,
                  altFormat: 'j-m-Y at H:i',
                  enableTime: true,
                  dateFormat: 'Y-m-d H:i:ss',
                  minDate: discountStart,
                  time_24hr: true,
                }"
            />
          </b-form-group>
        </b-col>

        <!-- discounts message -->
        <b-col  md="12">
          <b-form-group
              label="Discount Message"
              label-for="message"
          >
            <small>Shortcuts: </small>
            <small>
              <span v-for="(symbol, index) in helpers.discount.symbols"
                    v-bind:key="index"
                    class="input-shortcut"
                    @click="insertShortcut(symbol.symbol, helpers.discount.inputRef, 'discountMessage')"
                    v-b-tooltip.hover.top="symbol.description">
                {{ symbol.symbol }}
              </span>,
            </small>
            <b-form-input
                id="message"
                v-model="discountMessage"
                placeholder="Marketing attractive message"
                class="input"
                type="text"
                ref="discountMessageInput"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <!-- end of form-->
      <b-row class="mb-1">

        <!-- save button-->
        <b-col
          md="12" class="text-right"
        >
          <b-button
            @click="handleOk()"
          >
            Save
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
  import {
    BModal,
    BRow,
    BCol,
    BButton,
    // BCardText,
    // BBadge,
    BSpinner,
    BFormGroup,
    BFormCheckbox,
    BFormInput,
    VBTooltip,
    // BFormDatepicker,
  } from 'bootstrap-vue'
  import vSelect from 'vue-select'
  import store from '@/store'
  import flatPickr from 'vue-flatpickr-component'
  import Ripple from 'vue-ripple-directive'
  import coursesStoreModule from '../../coursesStoreModule'

  const discountTypes = ['percentage', 'amount']

  export default {
    name: 'PromoPrices',
    components: {
      BModal,
      BRow,
      BCol,
      BButton,
      // BCardText,
      // BBadge,
      BSpinner,
      BFormGroup,
      BFormCheckbox,
      BFormInput,
      // BFormDatepicker,
      vSelect,
      flatPickr,
    },
    directives: {
      'b-tooltip': VBTooltip,
      Ripple,
    },
    model: {
      prop: 'isModalSActive',
      event: 'update:isModalSActive',
    },
    props: {
      courseId: {
        type: Number,
        default: 99,
      },
      isModalSActive: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        isModalActive: true,
        options: [],
        spinner: true,
        isEarlyBird: false,
        isDiscounted: false,
        earlyBirdDays: 30,
        earlyBirdDiscountPercent: 6,
        typesOptions: discountTypes,
        selectedType: discountTypes[0],
        discountAmount: 0,
        discountStart: '',
        discountEnd: '',
        discountMessage: '',
        earlybirdMessage: '',
        helpers: {
          earlyBird: {
            symbols: [
              { symbol: '[end.d]', description: 'Discount end day ex: "22-12-2024"' },
              { symbol: '[remaining.t]', description: 'Discount remaining days before it ends ex: "8 days"' },
              { symbol: '[saved.a]', description: 'Discount saved percent ex: "15%"' },
              { symbol: '[bold]', description: 'Make the text bold' },
            ],
            inputRef: 'earlybirdMessageInput',
          },
          discount: {
            symbols: [
              { symbol: '[start.d]', description: 'Discount starting day ex: "22-12-2024"' },
              { symbol: '[end.d]', description: 'Discount end day ex: "22-12-2024"' },
              { symbol: '[remaining.t]', description: 'Discount remaining days before it ends ex: "8 days"' },
              { symbol: '[saved.a]', description: 'Discount saved amount or percent ex: "50 NOK" or "15 %"' },
              { symbol: '[bold]', description: 'Make the text bold' },
            ],
            inputRef: 'discountMessageInput',
          },
        },
      }
    },
    mounted() {
      const USER_APP_STORE_MODULE_NAME = 'app-courses'
      // Register module
      if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, coursesStoreModule)
      this.fetchDiscount()
    },
    methods: {
      /* eslint-disable object-shorthand */
      fetchDiscount() {
        this.spinner = true
        store.dispatch('app-courses/getDiscountByCourse', { course_id: this.courseId })
          .then(data => {
            this.isEarlyBird = data.is_earlybird
            this.earlyBirdDays = data.days_before
            this.earlyBirdDiscountPercent = data.earlybird_amount
            this.isDiscounted = data.is_discounted
            this.selectedType = data.discount_type
            this.discountAmount = data.discount_amount
            this.discountStart = data.date_start
            this.discountEnd = data.date_end
            this.discountMessage = data.discount_message ?? null
            this.earlybirdMessage = data.earlybird_message ?? null

            this.spinner = false
          })
      },
      dateDisabled(ymd, date) {
        if (this.discountStart !== '' && new Date(this.discountStart) < date) {
          return false
        }
        return true
      },
      /* eslint-disable object-shorthand */
      handleOk() {
        store.dispatch('app-courses/setCourseDiscount', { // setCourseDiscounts
          isEarlyBird: this.isEarlyBird,
          isDiscounted: this.isDiscounted,
          earlyBirdDays: this.earlyBirdDays,
          earlyBirdDiscountPercent: this.earlyBirdDiscountPercent,
          selectedType: this.selectedType,
          discountAmount: this.discountAmount,
          discountStart: this.discountStart,
          discountEnd: this.discountEnd,
          course_id: this.courseId,
          discountMessage: this.discountMessage,
          earlybirdMessage: this.earlybirdMessage,
        })
          .then(() => {
            const resp = store.state.coursesStoreModule.discountResponse.response
            if (resp === 'success') {
              // this.$emit('refetch-data')
              this.$emit('update:isModalSActive', false)
              this.selectedType = null
              this.discountStart = null
              this.discountEnd = null
              this.discountMessage = null
              this.earlybirdMessage = null
            }
          })
      },
      insertShortcut(symbol, inputRef, vmodel) {
        const currentInput = this.$refs.[inputRef]
        const text = currentInput.value ?? ' '
        let startPosition = currentInput.selectionStart
        const endPosition = currentInput.selectionEnd
        // console.log(startPosition, endPosition, text)
        const textBefore = text.substring(0, startPosition)
        const textAfter = text.substring(endPosition, text.length)
        let newValue = ''
        const textSelected = text.substring(startPosition, endPosition)

        // wrap the text with tags
        if (startPosition !== endPosition && symbol === '[bold]' && textSelected.length > 0) {
          // eslint-disable-next-line
          newValue = textBefore + '<b>' + textSelected + '</b>' + textAfter
        } else {
          const newSymbol = (symbol !== '[bold]') ? symbol : ''
          newValue = textBefore + newSymbol + textAfter
        }

        currentInput.value = newValue
        this.[vmodel] = newValue
        startPosition = symbol.length + startPosition

        // give time to vuejs to propagate the input changes in state and rerender
        setTimeout(() => {
          currentInput.focus()
          currentInput.setSelectionRange(startPosition, startPosition)
          // currentInput.data = 'test'
        }, 50)
      },
    },
  }
</script>

<style scoped>
.input-shortcut {
  color: #2193f7;
  cursor: pointer;
}
[dir=ltr] .input {
  border-right-style: groove !important;
  border-left-style: groove !important;
  border-top-style: groove !important;
 }
</style>
